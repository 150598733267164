import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getStorage, ref, listAll, getMetadata } from 'firebase/storage';

function Clients() {
  const [clients, setClients] = useState([]);

useEffect(() => {
  async function listClientsAndFiles() {
    const storage = getStorage();
    const storageRef = ref(storage, 'Klijenti');
    const res = await listAll(storageRef);
    const clientNames = res.prefixes.map((itemRef) => itemRef.name);

    const clientsWithUnprintedReceiptsCount = await Promise.all(
      clientNames.map(async (clientName) => {
        const clientRef = ref(storageRef, clientName);
        const clientRes = await listAll(clientRef);
        const fileNames = clientRes.items.map((item) => item.name);

        let count = 0;
        for (const element of fileNames) {
          const fileRef = ref(clientRef, element);
          const metadata = await getMetadata(fileRef);
          if (metadata.customMetadata?.isPrinted === "false" || !metadata.customMetadata?.isPrinted) {
            count++;
          }
        }

        return { name: clientName, unprintedReceiptsCount: count };
      })
    );

    setClients(clientsWithUnprintedReceiptsCount);
  }

  listClientsAndFiles();
}, []);

return (
  <div>
    <h3>Klijenti</h3>

    <table role="grid">
        <thead>
          <tr>
            <th>Klijent</th>
            <th>Neodštampani računi</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client, index) => (
            <tr key={index}>
              <td><Link to={`/receipts/${client.name}`}>{client.name}</Link></td>
              <td>{client.unprintedReceiptsCount}</td>
            </tr>
          ))}
        </tbody>
      </table>
  </div>
);
}

export default Clients;