import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { auth } from './firebase';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import Clients from './Clients';
import Receipts from './Receipts';

import '@picocss/pico'


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState(null); // Add this line
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setError('...');
      console.log('Logging in...')
      await signInWithEmailAndPassword(auth, email, password);
      setIsLoggedIn(true);
      // User logged in successfully
    } catch (error) {
      // Error occurred during login
      console.error(error);
      setError('Pogrešan email ili lozinka!');
    }
  };
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/clients');
    }
  }, [isLoggedIn, navigate]);


  // Set up the listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, navigate to clients
        navigate('/clients');
      }
    });

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, [navigate]);
  return (
    <div className="login-container">
      <h3>Dobrodošli</h3>
      {error && <p>{error}</p>} {/* Add this line */}
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email:</label>
        <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required />
        <label htmlFor="password">Lozinka:</label>
        <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" required />
        <button type="submit">Prijavi se</button>
      </form>
    </div>
  );
}


function CheckAuth(){

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        // User is signed in, navigate to clients
        navigate('/login');
      }
    });

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, [navigate]);

}

function App() {

  return (
    <Router>
      <CheckAuth />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/receipts/:fileName" element={<Receipts />} />

        <Route path="*" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;