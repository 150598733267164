import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBdIqJMUIGhdY6DyU4NbOxOrxvRGP4xeMA",
    authDomain: "nimi-225118.firebaseapp.com",
    databaseURL: "https://nimi-225118.firebaseio.com",
    projectId: "nimi-225118",
    storageBucket: "nimi-225118.appspot.com",
    messagingSenderId: "129243914790",
    appId: "1:129243914790:web:f841ff6cdbf3f12a163ce7",
    measurementId: "G-EJ77LP05WK"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };