import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getStorage, ref, listAll, getMetadata, updateMetadata, getDownloadURL, deleteObject } from 'firebase/storage';


function Receipts() {
  const { fileName } = useParams();
  const [objects, setObjects] = useState([]);

  const handleDownload = async (object) => {
    const url = await getDownloadURL(object.ref);
    window.open(url, '_blank');
  };

  useEffect(() => {
    async function listObjects() {
      const storage = getStorage();
      const storageRef = ref(storage, `Klijenti/${fileName}`);
      const res = await listAll(storageRef);
      const objectData = await Promise.all(
        res.items.map(async (itemRef) => {
          const metadata = await getMetadata(itemRef);
          return { ref: itemRef, name: itemRef.name, timeCreated: metadata.timeCreated, isPrinted: metadata.customMetadata?.isPrinted === 'true' };
        })
      );
      setObjects(objectData);
    }

    listObjects();
  }, [fileName]);

  const handlePrintedChange = async (object, event) => {
    const newIsPrinted = event.target.checked;
    await updateMetadata(object.ref, { customMetadata: { isPrinted: newIsPrinted.toString() } });
    object.isPrinted = newIsPrinted;
    setObjects([...objects]);
  };

  function handleDelete(object) {
  if (window.confirm('Da li ste sigurni da želite da obrišete ovaj fajl?')) {

    deleteObject(object.ref)
      .then(() => {
        setObjects(objects.filter(obj => obj.ref.fullPath !== object.ref.fullPath));
      })
      .catch((error) => {
        console.error('Uh-oh, an error occurred!', error);
        window.alert('Došlo je do greške prilikom brisanja fajla. Pokušajte ponovo.');
      });
  }
}

  return (
    <div>
      <h3>Računi za: {fileName}</h3>
      <p>
      <Link to="/clients">Nazad na listu klijenata</Link>
      </p>
      <table role="grid">
        <thead>
          <tr>
            <th>Naziv računa</th>
            <th>Datum</th>
            <th>Odštampan</th>
            <th>Otvori</th>
            <th>Brisanje</th>
          </tr>
        </thead>
        <tbody>
          {objects.map((object, index) => (
            <tr key={index}>
              <td>{object.name}</td>
              <td>{new Date(object.timeCreated).toLocaleString()}</td>
              <td>
                <input type="checkbox" checked={object.isPrinted} onChange={(event) => handlePrintedChange(object, event)} />
              </td>
              <td>
                <button onClick={() => handleDownload(object)}>Preuzmi</button>
              </td>
              <td>
                <button className='secondary'  onClick={() => handleDelete(object)}>Obriši</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Receipts;